import { LocalStorage } from "@/core/storage/LocalStorage";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";
const localStorage = new LocalStorage();

export class Timer {
  private intervalId: any;
  private duration: number = 0;

  startTimer(
    updateDuration: (formattedDuration: string) => void,
    duration?: number
  ): void {
    if (duration) {
      this.duration = duration;
    }
    this.intervalId = setInterval(() => {
      this.duration++;
      const formattedDuration = this.formatDuration(this.duration);
      localStorage.setItem(LOCAL_STORAGE.TIMER, formattedDuration);
      updateDuration(formattedDuration);
    }, 1000);
  }

  formatDuration(totalSeconds: number): string {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  }

  stopTimer() {
    localStorage.removeItem(LOCAL_STORAGE.TIMER);
    clearInterval(this.intervalId);
  }

  justStopTimer() {
    clearInterval(this.intervalId);
  }

  setDuration(newDuration: number): void {
    this.duration = newDuration;
  }
}
