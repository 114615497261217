
import { ErrorMessage, Field, Form } from "vee-validate";

import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { ScrollComponent } from "@/presentation/assets/ts/components/_ScrollComponent";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import Swal from "sweetalert2";
import { environment } from "../../../../../../environment";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Timer } from "@/presentation/helper/Timer";
import SocketService from "@/infrastructure/web-socket/SocketService";
import { MessageFlow } from "@/presentation/helper/MessageFlow";
import MediaRecorderService from "@/presentation/helper/MediaRecorder";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { BaseWsRequestDto } from "@/domain/base/BaseWsRequestDto";
import { InterviewSessionResponseDto } from "@/domain/interviewer/interview/model/ChatSectionResponseDto";
import { JobPostingDetailByInviteModel } from "@/domain/interviewer/job-posting-detail-by-invite-key/model/JobPostingDetailByInviteModel";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";

export default defineComponent({
  name: "Interview",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  methods: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    let interviewKey: undefined | string = undefined;
    const isInterviewSessionActive = ref(false);

    const socketService = new SocketService();
    const videoSocketService = new SocketService();
    const sessionId = ref(0);
    const mediaRecorderService = new MediaRecorderService();
    const fileController = store.state.ControllersModule.fileController;
    const swalNotification = new SwalNotification();

    const videoElement = ref<null | HTMLVideoElement>(null);
    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);
    const isGifPlaying = ref(false);
    const isRecording = ref(false);
    const isLoading = ref<boolean>(false);
    const isActiveUserCamera = ref(false);
    const isWaitingForResponse = ref(false);
    const isLoadingUserCamera = ref(false);
    const isLoadingResponse = ref(false);
    const isError = ref(false);
    const isAiActive = ref(false);
    const audioList: HTMLAudioElement[] = [];
    const AItext = ref("");
    const AiSpeakSecond = ref(0);
    let video: any;

    const timer = new Timer();

    let completionPercentage = ref(0);
    const duration = ref("00:00");
    const sessionTime = localStorage.getItem(LOCAL_STORAGE.TIMER);

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const jobPostingDetail = ref<JobPostingDetailByInviteModel>({});

    const messages = ref<Array<any>>([
      {
        text: "Mülakata hazır olduğunuzda 'Mülakata Başla' butonuna tıklayınız.",
      },
    ]);

    onBeforeUnmount(() => {
      timer.justStopTimer();
    });

    watch(AItext, (newValue) => {
      if (messages.value.length > 0) {
        messages.value[messages.value.length - 1].text = newValue;
      }
    });

    const getJobPostingDetail = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailByInviteModel = {
        key: interviewKey,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingDetailByInvite(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            console.log("test");
            Object.assign(jobPostingDetail.value, response.getValue());
          } else {
            router.push({ name: "interviewFailed" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const startChat = () => {
      const urlPath = `/interview?key=${interviewKey}`;

      socketService.connect(urlPath, () => {
        isLoadingUserCamera.value = true;
        if (socketService.isConnected()) {
          startUserCamera();
          timer.startTimer((formattedDuration: string) => {
            duration.value = formattedDuration;
          });
          isAiActive.value = true;
          isInterviewSessionActive.value = true;
          isLoadingResponse.value = true;
          messages.value = [];
          showStartChatAlert();

          socketService.addEventListener("message", handleMessage);
        }
      });
    };

    const handleMessage = (message: any) => {
      // message {"data":null,"error":{"status":403,"name":"ForbiddenError","message":"Forbidden","details":{}}}

      console.log("message.data", JSON.parse(message).data);
      if (JSON.parse(message).data == null) {
        timer.justStopTimer();
        isLoadingResponse.value = false;
        isError.value = true;
        stopUserCamera();

        Swal.fire({
          text: "Mülakat başlatılamadı, geçersiz link ya da token limiti dolmuş olabilir. lütfen Yönetici ile iletişime geçin.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
          heightAuto: false,
        }).then(() => router.push({ name: "landingPage" }));
        document.body.classList.remove("swal2-shown", "swal2-height-auto");
        document.documentElement.classList.remove("swal2-height-auto");
      } else {
        let res: InterviewSessionResponseDto = JSON.parse(message);
        if (Number(JSON.parse(message).data.attributes.sessionId)) {
          sessionId.value = Number(
            JSON.parse(message).data.attributes.sessionId
          );
        }

        processResponse(res);
      }
    };

    const processResponse = (res: InterviewSessionResponseDto) => {
      const newMessage = res.data.attributes.assistantText;

      isWaitingForResponse.value = false;

      if (res.data.attributes.voice.data) {
        let url =
          environment.baseUploadUrl +
          res.data.attributes.voice.data.attributes.url;
        startAIVoice(url).then(() => {
          MessageFlow(
            newMessage,
            Number(AiSpeakSecond.value),
            messages.value,
            messages.value.length - 1
          );
        });
      }

      messages.value.push({ text: newMessage });
      completionPercentage.value = res.data.attributes.completionPercentage;

      if (res.data.attributes.endDate) {
        setTimeout(() => {
          Swal.fire({
            title: "Tebrikler!",
            text: "Mülakatı başarıyla tamamladınız...",
            icon: "success",
            heightAuto: false,
          }).then(() => {
            timer.justStopTimer();
            stopUserCamera();
            stopRecording();
            stopAIVoice();
            router.push({ name: "interviewResult" });
          });
          document.body.classList.remove("swal2-shown", "swal2-height-auto");
          document.documentElement.classList.remove("swal2-height-auto");
        }, 3000);
      }
    };
    const showStartChatAlert = () => {
      Swal.fire({
        text: "Mülakat başladı, başarılar!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
        heightAuto: false,
      }).then(() => router.push({ name: "doInterview" }));
      document.body.classList.remove("swal2-shown", "swal2-height-auto");
      document.documentElement.classList.remove("swal2-height-auto");
    };

    const startRecording = async () => {
      isRecording.value = true;
      stopAIVoice();

      mediaRecorderService.startAudioRecording();
    };

    const startUserCamera = async () => {
      isActiveUserCamera.value = true;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        isLoadingUserCamera.value = false;

        if (videoElement.value) {
          videoElement.value.srcObject = stream;
          videoElement.value.style.display = "block";
        }
      } catch (error) {
        Swal.fire({
          text: "Kamera erişimi reddedildi veya kullanılamıyor.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
          heightAuto: false,
        }).then(function () {
          router.push({ name: "landingPage" });
        });
        document.body.classList.remove("swal2-shown", "swal2-height-auto");
        document.documentElement.classList.remove("swal2-height-auto");
      }
    };

    const startVideoRecordingPeriodically = () => {
      mediaRecorderService.startVideoRecordingPeriodically(
        videoElement.value,
        videoSocketService.getSocket()
      );
    };

    const stopUserCamera = async () => {
      mediaRecorderService.stopVideoRecording();

      if (videoElement.value) {
        const stream = videoElement.value.srcObject as MediaStream;
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
        videoElement.value.srcObject = null;
        videoElement.value.style.display = "none";
      }
    };

    const stopRecording = () => {
      isRecording.value = false;
      isWaitingForResponse.value = true;
      if (
        mediaRecorderService.audioRecorder &&
        mediaRecorderService.audioRecorder.state !== "inactive"
      ) {
        mediaRecorderService.stopAudioRecording();
        if (mediaRecorderService.audioRecorder)
          mediaRecorderService.audioRecorder.onstop = () => {
            console.log(mediaRecorderService.videoChunks);
            const audioBlob = new Blob(mediaRecorderService.audioChunks, {
              type: "audio/mp3",
            });
            sendBlobToAPI(audioBlob);
            mediaRecorderService.audioChunks = [];
          };
      }
    };

    const sendBlobToAPI = (audioBlob: Blob) => {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener("loadedmetadata", () => {
        if (
          audioElement.duration === Infinity ||
          isNaN(Number(audioElement.duration))
        ) {
          audioElement.currentTime = 1e101;
          audioElement.addEventListener("timeupdate", getDuration);
        }
      });

      function getDuration(event) {
        event.target.currentTime = 0;
        event.target.removeEventListener("timeupdate", getDuration);
        console.log(event.target.duration);
        if (event.target.duration < 1) {
          isLoadingResponse.value = false;
          Swal.fire({
            text: "Lütfen daha uzun ses kaydediniz.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
            heightAuto: false,
          }).then(function () {
            router.push({ name: "doInterview" });
          });
          document.body.classList.remove("swal2-shown", "swal2-height-auto");
          document.documentElement.classList.remove("swal2-height-auto");
        } else {
          const soundFile = new File([audioBlob], "userAudio.mp3", {
            type: "audio/mp3",
          });

          const soundUploadModel = new FileUploadModel(soundFile);

          isLoadingResponse.value = true;

          fileController
            .fileUpload(soundUploadModel)
            .then((response) => {
              if (response.isSuccess) {
                let res = response.getValue();
                let soundId = res.id;

                const data = {
                  voice: soundId,
                };

                const request: BaseWsRequestDto = {
                  data: data,
                  type: "voiceAnalysis",
                };

                socketService.sendMessage(JSON.stringify(request));
              } else {
                console.log("error", response.error.cause.cause);
                Swal.fire({
                  text: "Lütfen tekrar deneyiniz.",
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: "Tamam",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                  heightAuto: false,
                }).then(function () {
                  router.push({ name: "doInterview" });
                });
                document.body.classList.remove(
                  "swal2-shown",
                  "swal2-height-auto"
                );
                document.documentElement.classList.remove("swal2-height-auto");
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      }
    };

    const stopChat = async () => {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Mülakatı sonlandırmak ister misiniz?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        cancelButtonText: "İptal",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Görüşme sonlandı.",
            text: "Görüşmeyi başarıyla sonlandırdınız.",
            icon: "success",
            heightAuto: false,
          }).then(() => {
            const request: BaseWsRequestDto = {
              type: "closeSession",
            };

            socketService.sendMessage(JSON.stringify(request));
            isAiActive.value = false;
            timer.justStopTimer();
            stopUserCamera();
            stopRecording();
            stopAIVoice();
            router.push({ name: "interviewResult" });
          });
          document.body.classList.remove("swal2-shown", "swal2-height-auto");
          document.documentElement.classList.remove("swal2-height-auto");
        }

        router.push({ name: "doInterview" });
      });
      document.body.classList.remove("swal2-shown", "swal2-height-auto");
      document.documentElement.classList.remove("swal2-height-auto");
    };

    const startAIVoice = (url: string) => {
      return new Promise((resolve, reject) => {
        isLoadingResponse.value = false;
        isGifPlaying.value = true;
        const aiVoice = new Audio(url);

        aiVoice.addEventListener("loadedmetadata", () => {
          AiSpeakSecond.value = aiVoice.duration;
          resolve(aiVoice.duration);
        });

        aiVoice.play();
        audioList.push(aiVoice);

        aiVoice.onended = () => {
          isGifPlaying.value = false;
        };

        aiVoice.onerror = (error) => {
          reject(error);
        };
      });
    };

    const stopAIVoice = () => {
      audioList.forEach((audio) => {
        if (!audio.paused) {
          audio.pause();
          isGifPlaying.value = false;
          audio.currentTime = 0;
        }
      });
    };

    watch(
      () => sessionId.value,
      () => {
        if (sessionId.value != 0) {
          const urlPath = `/interview-stream?id=${sessionId.value}`;
          console.log("urlPath", urlPath);
          videoSocketService.connect(urlPath, () => {
            if (socketService.isConnected()) {
              startVideoRecordingPeriodically();
            }
          });
        }
      }
    );

    onMounted(() => {
      interviewKey = route.query.key?.toString();
      getJobPostingDetail();
      ScrollComponent.bootstrap();
      if (!messagesRef.value) {
        return;
      }
    });

    return {
      isAiActive,
      isLoadingResponse,
      isInterviewSessionActive,
      isActiveUserCamera,
      isLoadingUserCamera,
      isWaitingForResponse,
      startChat,
      stopChat,
      startRecording,
      stopRecording,
      messagesInRef,
      messagesOutRef,
      videoElement,
      isGifPlaying,
      isRecording,
      AItext,
      completionPercentage,
      messages,
      messagesRef,
      jobPostingDetail,
    };
  },
});
