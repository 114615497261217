export default class MediaRecorderService {
  videoRecorder: MediaRecorder | null = null;
  audioRecorder: MediaRecorder | null = null;
  audioChunks: Blob[] = [];
  videoChunks: Blob[] = [];
  sendInterval: NodeJS.Timeout | null = null; 

  constructor() {
    this.videoRecorder = null;
    this.audioRecorder = null;
    this.audioChunks = [];
    this.videoChunks = [];
  }

  startVideoRecording(videoElement) {
    const stream = videoElement.srcObject;

    if (stream) {
      // navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      this.videoRecorder = new MediaRecorder(stream);

      this.videoRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          this.videoChunks.push(event.data);
        }
      };

      this.videoRecorder.onstop = () => {
        const videoBlob = new Blob(this.videoChunks, { type: 'video/webm; codecs="vp8, opus"' });
        const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
        
        const blobs = {
          video: videoBlob,
          audio: audioBlob,
        };

        return blobs;
      };

      this.videoRecorder.start();
    // });
    }
  }
  

  startAudioRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      this.audioRecorder = new MediaRecorder(stream);
      this.audioRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
      };
      this.audioRecorder.start();
    });
  }

  stopAudioRecording() {
    if (this.audioRecorder) {
      this.audioRecorder.stop();
    }
  }

  stopVideoRecording() {
    if (this.videoRecorder) {
      this.videoRecorder.stop();
    }
  }

  startVideoRecordingPeriodically(videoElement, socket) {
    const stream = videoElement.srcObject;

    if (stream) {
      this.videoRecorder = new MediaRecorder(stream, {
        mimeType: 'video/webm; codecs="vp8, opus"',
      });

      // `ondataavailable` ile her chunk'ı WebSocket üzerinden gönderiyoruz
      this.videoRecorder.ondataavailable = (event) => {
        if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
          socket.send(event.data);
        }
      };

      this.videoRecorder.start(100); // 100ms'de bir chunk
    }
  }
}
