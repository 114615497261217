<template>
  <div class="d-flex flex-column flex-lg-row">
    <div
      class="
        flex-column flex-lg-row-auto
        w-100 w-lg-300px w-xl-800px
        mb-10 mb-lg-0
      "
    >
      <div class="card mb-5 mb-xxl-8 bg-white rounded shadow-sm">
        <div class="card-body">
          <div class="row" style="position: relative">
            <img
              :src="
                isGifPlaying
                  ? '/media/modules/pollster/woman-talking.gif'
                  : '/media/modules/pollster/woman.gif'
              "
              alt="AI"
              class="img-fluid"
            />
          </div>

          <div class="row">
            <!-- Progress Bar -->
            <div class="mb-6 mt-7">
              <div class="progress">
                <div
                  class="
                    progress-bar progress-bar-striped progress-bar-animated
                  "
                  role="progressbar"
                  :style="{ width: completionPercentage + 1 + '%' }"
                  :aria-valuenow="completionPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <!-- Question Info -->
            <!-- <div class="d-flex justify-content-between">
              <h6>{{ $t("modals.interview.question") }} {{ 2 }}/{{ 8 }}</h6>
              <span class="badge bg-warning">{{
                $t("modals.interview.openEnded")
              }}</span>
            </div> -->

            <!-- Question Text -->

            <div class="mt-6 mb-6 text-center">
              <h4 v-if="isWaitingForResponse">Cevap bekleniyor...</h4>
              <h4 v-else-if="messages.length > 0">
                {{ messages[messages.length - 1].text }}
              </h4>
              <!-- isWaitingForResponse değerine göre eğer waiting true ise cevap bekleniyor... yazmalı -->
            </div>
          </div>

          <div class="text-center mt-5">
            <button
              v-if="isInterviewSessionActive == true"
              @mousedown="startRecording"
              :disabled="isAiActive == false || isLoadingResponse"
              @mouseup="stopRecording"
              :class="{
                'btn btn-warning': !isRecording,
                'btn btn-danger': isRecording,
              }"
              type="button"
              data-kt-element="send"
              class="w-100 w-md-25"
            >
              {{ $t("modals.interview.pushToTalk") }}
            </button>
            <button
              v-else
              class="btn btn-success w-100 w-md-25"
              type="button"
              data-kt-element="send"
              @click="startChat"
            >
              {{ $t("modals.interview.startInterview.title") }}
            </button>
          </div>
          <div v-if="isRecording" class="text-center mt-4">
            <div class="alert alert-danger d-inline-block px-4 py-2 fw-bold">
              🎤 Ses kaydediliyor...
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card ms-lg-7 ms-xl-10 bg-white rounded shadow-sm">
      <div class="card bg-white rounded shadow-sm">
        <div class="card-header" id="kt_chat_messenger_header">
          <div class="card-title">
            <div
              class="
                d-flex
                justify-content-center
                text-center text-align-center
                flex-column
                me-3
              "
            >
              <a
                href="#"
                class="
                  fs-4
                  fw-bolder
                  text-center text-gray-900 text-hover-primary
                  me-1
                  mb-2
                  lh-1w
                "
              >
                {{ $t("modals.interview.candidate.candidateInfo") }}
              </a>
            </div>
          </div>
        </div>

        <div class="card-body text-center">
          <div
            v-if="isActiveUserCamera != true && isLoadingUserCamera != true"
            class="placeholder-name"
          >
            {{ jobPostingDetail.candidateFullName }}
          </div>
          <!-- Kamera açık ise video göster -->
          <div v-else-if="isActiveUserCamera">
            <video
              ref="videoElement"
              id="userCamera"
              height="370"
              autoplay
              class="w-100"
              style="object-fit: cover"
            ></video>
          </div>
          <div
            v-else-if="isLoadingUserCamera"
            class="loading-spinner"
            style="
              height: 370px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div class="text-center px-5 pb-7">
              <img
                class="max-h-25px"
                src="/media/loading_icon.gif"
                style="width: 35px"
              />
            </div>
          </div>

          <!-- Ad Soyad ve Meslek -->
          <h4 class="text-gray-800 fw-bolder mb-2 mt-6">
            {{ jobPostingDetail.candidateFullName }}
          </h4>
          <a class="text-hover-primary fs-7">
            {{ jobPostingDetail.candidateEmail }}</a
          >

          <!-- Detaylar -->
          <div class="text-start mt-4">
            <div class="row mb-7">
              <!-- İlan Başlığı -->
              <div class="col-md-6 mb-5">
                <div class="card border border-gray-300 shadow-sm rounded">
                  <div class="card-body text-center">
                    <div class="fw-bold text-gray-600">
                      {{ $t("modals.interview.startInterview.jobPosting") }}
                    </div>
                    <h4 class="mt-2 text-dark">
                      {{ jobPostingDetail.jobPostingTitle }}
                    </h4>
                  </div>
                </div>
              </div>

              <!-- Mülakat Türü -->
              <div class="col-md-6 mb-5">
                <div class="card border border-gray-300 shadow-sm rounded">
                  <div class="card-body text-center">
                    <div class="fw-bold text-gray-600">
                      {{ $t("modals.interview.startInterview.interviewType") }}
                    </div>
                    <h4 class="mt-2 text-primary">
                      {{ jobPostingDetail.interviewTitle }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-footer pt-4 pb-5"
          id="kt_chat_messenger_footer"
          style="padding-bottom: 1"
        >
          <div class="text-center">
            <button
              @click="stopChat"
              :disabled="isAiActive == false || isLoadingResponse"
              class="btn btn-dark"
              type="button"
              data-kt-element="send"
            >
              {{ $t("modals.interview.finishInterview") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ErrorMessage, Field, Form } from "vee-validate";

import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { ScrollComponent } from "@/presentation/assets/ts/components/_ScrollComponent";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import Swal from "sweetalert2";
import { environment } from "../../../../../../environment";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Timer } from "@/presentation/helper/Timer";
import SocketService from "@/infrastructure/web-socket/SocketService";
import { MessageFlow } from "@/presentation/helper/MessageFlow";
import MediaRecorderService from "@/presentation/helper/MediaRecorder";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { BaseWsRequestDto } from "@/domain/base/BaseWsRequestDto";
import { InterviewSessionResponseDto } from "@/domain/interviewer/interview/model/ChatSectionResponseDto";
import { JobPostingDetailByInviteModel } from "@/domain/interviewer/job-posting-detail-by-invite-key/model/JobPostingDetailByInviteModel";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";

export default defineComponent({
  name: "Interview",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  methods: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    let interviewKey: undefined | string = undefined;
    const isInterviewSessionActive = ref(false);

    const socketService = new SocketService();
    const videoSocketService = new SocketService();
    const sessionId = ref(0);
    const mediaRecorderService = new MediaRecorderService();
    const fileController = store.state.ControllersModule.fileController;
    const swalNotification = new SwalNotification();

    const videoElement = ref<null | HTMLVideoElement>(null);
    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);
    const isGifPlaying = ref(false);
    const isRecording = ref(false);
    const isLoading = ref<boolean>(false);
    const isActiveUserCamera = ref(false);
    const isWaitingForResponse = ref(false);
    const isLoadingUserCamera = ref(false);
    const isLoadingResponse = ref(false);
    const isError = ref(false);
    const isAiActive = ref(false);
    const audioList: HTMLAudioElement[] = [];
    const AItext = ref("");
    const AiSpeakSecond = ref(0);
    let video: any;

    const timer = new Timer();

    let completionPercentage = ref(0);
    const duration = ref("00:00");
    const sessionTime = localStorage.getItem(LOCAL_STORAGE.TIMER);

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const jobPostingDetail = ref<JobPostingDetailByInviteModel>({});

    const messages = ref<Array<any>>([
      {
        text: "Mülakata hazır olduğunuzda 'Mülakata Başla' butonuna tıklayınız.",
      },
    ]);

    onBeforeUnmount(() => {
      timer.justStopTimer();
    });

    watch(AItext, (newValue) => {
      if (messages.value.length > 0) {
        messages.value[messages.value.length - 1].text = newValue;
      }
    });

    const getJobPostingDetail = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailByInviteModel = {
        key: interviewKey,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingDetailByInvite(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            console.log("test");
            Object.assign(jobPostingDetail.value, response.getValue());
          } else {
            router.push({ name: "interviewFailed" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const startChat = () => {
      const urlPath = `/interview?key=${interviewKey}`;

      socketService.connect(urlPath, () => {
        isLoadingUserCamera.value = true;
        if (socketService.isConnected()) {
          startUserCamera();
          timer.startTimer((formattedDuration: string) => {
            duration.value = formattedDuration;
          });
          isAiActive.value = true;
          isInterviewSessionActive.value = true;
          isLoadingResponse.value = true;
          messages.value = [];
          showStartChatAlert();

          socketService.addEventListener("message", handleMessage);
        }
      });
    };

    const handleMessage = (message: any) => {
      // message {"data":null,"error":{"status":403,"name":"ForbiddenError","message":"Forbidden","details":{}}}

      console.log("message.data", JSON.parse(message).data);
      if (JSON.parse(message).data == null) {
        timer.justStopTimer();
        isLoadingResponse.value = false;
        isError.value = true;
        stopUserCamera();

        Swal.fire({
          text: "Mülakat başlatılamadı, geçersiz link ya da token limiti dolmuş olabilir. lütfen Yönetici ile iletişime geçin.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
          heightAuto: false,
        }).then(() => router.push({ name: "landingPage" }));
        document.body.classList.remove("swal2-shown", "swal2-height-auto");
        document.documentElement.classList.remove("swal2-height-auto");
      } else {
        let res: InterviewSessionResponseDto = JSON.parse(message);
        if (Number(JSON.parse(message).data.attributes.sessionId)) {
          sessionId.value = Number(
            JSON.parse(message).data.attributes.sessionId
          );
        }

        processResponse(res);
      }
    };

    const processResponse = (res: InterviewSessionResponseDto) => {
      const newMessage = res.data.attributes.assistantText;

      isWaitingForResponse.value = false;

      if (res.data.attributes.voice.data) {
        let url =
          environment.baseUploadUrl +
          res.data.attributes.voice.data.attributes.url;
        startAIVoice(url).then(() => {
          MessageFlow(
            newMessage,
            Number(AiSpeakSecond.value),
            messages.value,
            messages.value.length - 1
          );
        });
      }

      messages.value.push({ text: newMessage });
      completionPercentage.value = res.data.attributes.completionPercentage;

      if (res.data.attributes.endDate) {
        setTimeout(() => {
          Swal.fire({
            title: "Tebrikler!",
            text: "Mülakatı başarıyla tamamladınız...",
            icon: "success",
            heightAuto: false,
          }).then(() => {
            timer.justStopTimer();
            stopUserCamera();
            stopRecording();
            stopAIVoice();
            router.push({ name: "interviewResult" });
          });
          document.body.classList.remove("swal2-shown", "swal2-height-auto");
          document.documentElement.classList.remove("swal2-height-auto");
        }, 3000);
      }
    };
    const showStartChatAlert = () => {
      Swal.fire({
        text: "Mülakat başladı, başarılar!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
        heightAuto: false,
      }).then(() => router.push({ name: "doInterview" }));
      document.body.classList.remove("swal2-shown", "swal2-height-auto");
      document.documentElement.classList.remove("swal2-height-auto");
    };

    const startRecording = async () => {
      isRecording.value = true;
      stopAIVoice();

      mediaRecorderService.startAudioRecording();
    };

    const startUserCamera = async () => {
      isActiveUserCamera.value = true;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        isLoadingUserCamera.value = false;

        if (videoElement.value) {
          videoElement.value.srcObject = stream;
          videoElement.value.style.display = "block";
        }
      } catch (error) {
        Swal.fire({
          text: "Kamera erişimi reddedildi veya kullanılamıyor.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
          heightAuto: false,
        }).then(function () {
          router.push({ name: "landingPage" });
        });
        document.body.classList.remove("swal2-shown", "swal2-height-auto");
        document.documentElement.classList.remove("swal2-height-auto");
      }
    };

    const startVideoRecordingPeriodically = () => {
      mediaRecorderService.startVideoRecordingPeriodically(
        videoElement.value,
        videoSocketService.getSocket()
      );
    };

    const stopUserCamera = async () => {
      mediaRecorderService.stopVideoRecording();

      if (videoElement.value) {
        const stream = videoElement.value.srcObject as MediaStream;
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
        videoElement.value.srcObject = null;
        videoElement.value.style.display = "none";
      }
    };

    const stopRecording = () => {
      isRecording.value = false;
      isWaitingForResponse.value = true;
      if (
        mediaRecorderService.audioRecorder &&
        mediaRecorderService.audioRecorder.state !== "inactive"
      ) {
        mediaRecorderService.stopAudioRecording();
        if (mediaRecorderService.audioRecorder)
          mediaRecorderService.audioRecorder.onstop = () => {
            console.log(mediaRecorderService.videoChunks);
            const audioBlob = new Blob(mediaRecorderService.audioChunks, {
              type: "audio/mp3",
            });
            sendBlobToAPI(audioBlob);
            mediaRecorderService.audioChunks = [];
          };
      }
    };

    const sendBlobToAPI = (audioBlob: Blob) => {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener("loadedmetadata", () => {
        if (
          audioElement.duration === Infinity ||
          isNaN(Number(audioElement.duration))
        ) {
          audioElement.currentTime = 1e101;
          audioElement.addEventListener("timeupdate", getDuration);
        }
      });

      function getDuration(event) {
        event.target.currentTime = 0;
        event.target.removeEventListener("timeupdate", getDuration);
        console.log(event.target.duration);
        if (event.target.duration < 1) {
          isLoadingResponse.value = false;
          Swal.fire({
            text: "Lütfen daha uzun ses kaydediniz.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
            heightAuto: false,
          }).then(function () {
            router.push({ name: "doInterview" });
          });
          document.body.classList.remove("swal2-shown", "swal2-height-auto");
          document.documentElement.classList.remove("swal2-height-auto");
        } else {
          const soundFile = new File([audioBlob], "userAudio.mp3", {
            type: "audio/mp3",
          });

          const soundUploadModel = new FileUploadModel(soundFile);

          isLoadingResponse.value = true;

          fileController
            .fileUpload(soundUploadModel)
            .then((response) => {
              if (response.isSuccess) {
                let res = response.getValue();
                let soundId = res.id;

                const data = {
                  voice: soundId,
                };

                const request: BaseWsRequestDto = {
                  data: data,
                  type: "voiceAnalysis",
                };

                socketService.sendMessage(JSON.stringify(request));
              } else {
                console.log("error", response.error.cause.cause);
                Swal.fire({
                  text: "Lütfen tekrar deneyiniz.",
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: "Tamam",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                  heightAuto: false,
                }).then(function () {
                  router.push({ name: "doInterview" });
                });
                document.body.classList.remove(
                  "swal2-shown",
                  "swal2-height-auto"
                );
                document.documentElement.classList.remove("swal2-height-auto");
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      }
    };

    const stopChat = async () => {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Mülakatı sonlandırmak ister misiniz?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        cancelButtonText: "İptal",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Görüşme sonlandı.",
            text: "Görüşmeyi başarıyla sonlandırdınız.",
            icon: "success",
            heightAuto: false,
          }).then(() => {
            const request: BaseWsRequestDto = {
              type: "closeSession",
            };

            socketService.sendMessage(JSON.stringify(request));
            isAiActive.value = false;
            timer.justStopTimer();
            stopUserCamera();
            stopRecording();
            stopAIVoice();
            router.push({ name: "interviewResult" });
          });
          document.body.classList.remove("swal2-shown", "swal2-height-auto");
          document.documentElement.classList.remove("swal2-height-auto");
        }

        router.push({ name: "doInterview" });
      });
      document.body.classList.remove("swal2-shown", "swal2-height-auto");
      document.documentElement.classList.remove("swal2-height-auto");
    };

    const startAIVoice = (url: string) => {
      return new Promise((resolve, reject) => {
        isLoadingResponse.value = false;
        isGifPlaying.value = true;
        const aiVoice = new Audio(url);

        aiVoice.addEventListener("loadedmetadata", () => {
          AiSpeakSecond.value = aiVoice.duration;
          resolve(aiVoice.duration);
        });

        aiVoice.play();
        audioList.push(aiVoice);

        aiVoice.onended = () => {
          isGifPlaying.value = false;
        };

        aiVoice.onerror = (error) => {
          reject(error);
        };
      });
    };

    const stopAIVoice = () => {
      audioList.forEach((audio) => {
        if (!audio.paused) {
          audio.pause();
          isGifPlaying.value = false;
          audio.currentTime = 0;
        }
      });
    };

    watch(
      () => sessionId.value,
      () => {
        if (sessionId.value != 0) {
          const urlPath = `/interview-stream?id=${sessionId.value}`;
          console.log("urlPath", urlPath);
          videoSocketService.connect(urlPath, () => {
            if (socketService.isConnected()) {
              startVideoRecordingPeriodically();
            }
          });
        }
      }
    );

    onMounted(() => {
      interviewKey = route.query.key?.toString();
      getJobPostingDetail();
      ScrollComponent.bootstrap();
      if (!messagesRef.value) {
        return;
      }
    });

    return {
      isAiActive,
      isLoadingResponse,
      isInterviewSessionActive,
      isActiveUserCamera,
      isLoadingUserCamera,
      isWaitingForResponse,
      startChat,
      stopChat,
      startRecording,
      stopRecording,
      messagesInRef,
      messagesOutRef,
      videoElement,
      isGifPlaying,
      isRecording,
      AItext,
      completionPercentage,
      messages,
      messagesRef,
      jobPostingDetail,
    };
  },
});
</script>

<style>
html .swal2-height-auto {
  height: 100% !important;
}

body .swal2-height-auto {
  height: 100% !important;
}

body .bg-body .swal2-shown .swal2-height-auto {
  height: 100% !important;
}

/* Kullanıcı adı ekranı */
.placeholder-name {
  background-color: grey;
  color: white;
  height: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

/* Yüklenme animasyonu */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Genişliği %100 yaparak, genişliği kapsayan alanda tamamlanmasını sağlar */
  height: 370px; /* Yükseklik sabit kalacak */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Transparan daire */
  border-top: 4px solid white; /* Dönen daire */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Dönen animasyon */
}

/* Spinner animasyonu */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
