import { environment } from '../../../environment';
import { ISocketService } from './ISocketService';

export default class SocketService implements ISocketService{
    private socket: WebSocket | null = null;
    private listeners: { [key: string]: Function[] } = {};
  
    // WebSocket bağlantısını başlat
    public connect(urlPath: string, onOpenCallback?: () => void): void {
      if (this.socket) {
        this.disconnect(); 
      }
  
      console.log("url", environment.baseWebSocketUrl + urlPath)
      this.socket = new WebSocket(environment.baseWebSocketUrl + urlPath);
  
      this.socket.onopen = () => {
        console.log('WebSocket bağlantısı açıldı.');
        if (onOpenCallback) {
          onOpenCallback(); 
        }
      };
  
      this.socket.onclose = () => {
        console.log('WebSocket bağlantısı kapandı.');
        this.socket = null; 
      };
  
      this.socket.onerror = (error) => {
        console.error('WebSocket hatası:', error);
      };
  
      this.socket.onmessage = (event) => {
        this.handleMessage(event.data);
      };
    }
  
    public isConnected(): boolean {
      console.log( this.socket !== null && this.socket.readyState === WebSocket.OPEN)
      return this.socket !== null && this.socket.readyState === WebSocket.OPEN;
    }

    // WebSocket bağlantısını kapat
    public disconnect(): void {
      if (this.socket) {
        this.socket.close();
      }
    }
  
    // Mesaj gönder
    public sendMessage(message: string): void {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(message);
      } else {
        console.error('WebSocket bağlantısı açık değil.');
      }
    }
  
    // Dinleyici ekle
    public addEventListener(event: string, callback: Function): void {
      if (event === "message" && this.socket) {
        this.socket.onmessage = (msgEvent) => {
          callback(msgEvent.data);
        };
      } else {
        if (!this.listeners[event]) {
          this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
      }
    }
  
    // Dinleyiciyi kaldır
    public removeEventListener(event: string, callback: Function): void {
      if (!this.listeners[event]) return;
  
      this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
    }
  
    // Gelen mesajları işleme
    private handleMessage(data: string): void {
      try {
        const parsedData = JSON.parse(data);
        const event = parsedData.event;
        const message = parsedData.message;
  
        if (this.listeners[event]) {
          this.listeners[event].forEach(callback => callback(message));
        }
      } catch (error) {
        console.error('Mesaj işlenirken hata oluştu:', error);
      }
    }

    public getSocket(): WebSocket | null {
      return this.socket;
    }


  }
  